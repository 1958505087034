/*!
 * Start Bootstrap - Creative v1.1.0 (http://startbootstrap.com/template-overviews/creative)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
@import url(//db.onlinewebfonts.com/c/116af611cbcd9e4bada60b4e700430c1?family=Avenir+Light);
@font-face {
    font-family: "Avenir Light";
    src: url("//db.onlinewebfonts.com/t/116af611cbcd9e4bada60b4e700430c1.eot");
    src: url("//db.onlinewebfonts.com/t/116af611cbcd9e4bada60b4e700430c1.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/116af611cbcd9e4bada60b4e700430c1.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/116af611cbcd9e4bada60b4e700430c1.woff") format("woff"), url("//db.onlinewebfonts.com/t/116af611cbcd9e4bada60b4e700430c1.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/116af611cbcd9e4bada60b4e700430c1.svg#Avenir Light") format("svg");
}

.relative{
  position: relative;
}
.btn-align-vert{
  padding-top:25px;
}
.txt-align-vert{
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.carousel-inner .carousel-item {
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  position: absolute;
  height: 50dvi;
}
.carousel-inner .carousel-item img {
  max-height: fit-content;
  max-width: 100%;
  vertical-align: middle;
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.carousel-inner .carousel-item:first-of-type {
  position: relative;
}
.carousel-inner .active {
  opacity: 1;
}


nav.scroll {
    opacity: 0.5;
    background-color: black !important;
    transition: background-color 200ms linear;
}

.nav-bg{
  opacity: 0.5;
  background-color: black !important;
  transition: background-color 200ms linear;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  
  height: 100%;
  width: 100%;
}
body {
    font-family: 'Avenir Light';
    color: #787b82;
}
hr {
  border-color: dimgrey;
  border-width: 3px;
  max-width: 50px;
}
hr.light {
  border-color: white;
}
a {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  color: #F05F40;
}
a:hover,
a:focus {
  color: dimgrey;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Bodoni MT", Didot, "Didot LT STD", "Book Antiqua", Garamond, "Times New Roman", serif;
}
p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.some-link {
  color:white;
  margin:10px;
}

.line {
  border-top: 1px solid #787b82;  
  width: 10%; 
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}
label{
  width: 100%;
}
input{
  width: 100%;
}
textarea{
  width: 100%;
  height: 100fr;
}
.container{
    margin-bottom:50px;
}
.bg-container {
    background-color: lightgrey;
    width: 100%;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 50px;
}

.bg-primary {
    background-color: #002F43;
}
.bg-primary-light {
  background-color: white;
}
.bg-dark {
  background-color: #222222;
  color: white;
}
.underline {
    text-decoration: underline;
}
.text-faded {
  color: rgba(0, 47, 67, 0.8);
}
section:before {
  height: 120px;
  content: "";
  display:block;
}
section::after {
  content: "";
  display:block;
}
aside {
  padding: 50px 0;
}
.no-padding {
  padding: 0;
}
@media (min-width: 768px) {
    .navbar .navbar-nav {
        display: inline-block;
        float: none;
        vertical-align: top;
    }

    .navbar .navbar-collapse {
        text-align: center;
    }
}

.nav-brand-title {
  padding: 15px; 
  border:double;
}
.nav-brand-title-mobile {
  padding: 15px; 
  border:double;
  font-size: 0.9rem;
}

.navbar-center{
  width: 100%; 
  text-align: center;
  position: relative;
}

.mobile-nav-brand {
  text-align: center;
  padding-left:0.5rem;
  display: inline-block;
}
.mobile-nav-item {
  padding: 30px; 
  display: inline-block;
}
.mobile-nav-link {
  font-size: 0.9rem;
}

.desktop-nav-item {
  display: inline-block;
}
.text-center {
    text-align: center
}
.img-wrap {
    width: 350px;
    height: 350px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0.5rem;
}
.img-wrap > img {
  width: auto;
    max-height: 100%;
    transform: translateX(-25%);
                /*max-width:100%;
                        height:auto;*/

}
img-wrap, .img-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0 50px;
}

    img-wrap, .img-content, .img-content-inner h1 {
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 15px;
    }

    img-wrap, .img-content, .img-content-inner hr {
        margin: 20px auto;
    }

    img-wrap, .img-content, .img-content-inner p {
        font-weight: 300;
        color: rgba(255, 255, 255, 0.7);
        font-size: 10px;
        margin-bottom: 50px;
    }

    /*@media (min-width: 768px) {
      .img-wrap {
        min-height: 100%;
      }
      img-wrap, .img-content {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 0 50px;
      }
      img-wrap, .img-content, .img-content-inner {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
      img-wrap, .img-content, .img-content-inner h1 {
        font-size: 25px;
      }
      img-wrap, .img-content, .img-content-inner p {
        font-size: 12px;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }*/








.topnav-centered a {
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
    .col-lg-offset-2 {
        margin-left: 16.666666666666664%;
    }
    .col-lg-offset-3 {
      margin-left: 25%;
  }
}

@media (max-width: 768px) {
  header{
    padding-top: 8.05rem;
  }
}
header {
    top: 0;
    width: 100%;
    min-height: auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    background-position: center;
    text-align: center;
    color: white;
}
header .carousel {
    position: relative;
    width: 100%;
    min-height: auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    background-position: center;
    text-align: center;
    color: white;
}

header .header-content {
  z-index: 1000;
  position: relative;
  text-align: center;
  padding: 100px 15px 100px;
  width: 100%;
}
header .header-content .header-content-inner h1 {
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
}
header .header-content .header-content-inner hr {
  margin: 20px auto;
}
header .header-content .header-content-inner p {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  header {
    min-height: 100%;
  }
  header .header-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 50px;
  }
  header .header-content .header-content-inner {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  header .header-content .header-content-inner h1 {
    font-size: 50px;
  }
  header .header-content .header-content-inner p {
    font-size: 18px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
.section-heading {
  margin-top: 0;
}
.service-box {
  max-width: 400px;
  margin: 50px auto 0;
}
@media (min-width: 992px) {
  .service-box {
    margin: 20px auto 0;
  }
}
.service-box p {
  margin-bottom: 0;
}
.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
}
.portfolio-box .portfolio-box-caption {
  color: white;
  opacity: 0;
  display: block;
  background: rgba(240, 95, 64, 0.9);
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category,
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  padding: 0 15px;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  font-size: 18px;
}
.portfolio-box:hover .portfolio-box-caption {
  opacity: 1;
}
.portfolio-box:focus {
  outline: none;
}
@media (max-width: 500px) {
      .small-hide {
        display:none;
  }
}
@media (min-width: 768px) {
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
    font-size: 16px;
  }
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    font-size: 22px;
  }
}
.call-to-action h2 {
  margin: 0 auto 20px;
}
.text-primary {
  color: #F05F40;
}
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.btn-default {
  color: #222222;
  background-color: white;
  border-color: white;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #222222;
  background-color: #f2f2f2;
  border-color: #ededed;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: white;
  border-color: white;
}
.btn-default .badge {
  color: white;
  background-color: #222222;
}
.btn-primary {
  color: white;
  background-color: #F05F40;
  border-color: #F05F40;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: white;
  background-color: #ee4b28;
  border-color: #ed431f;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #F05F40;
  border-color: #F05F40;
}
.btn-primary .badge {
  color: #F05F40;
  background-color: white;
}
.btn {
    border-block-color: #787b82;
    color: #787b82;
    width: 50%;
    border: solid;
    font-weight: 700;
    text-transform: uppercase;
    font-family:'Avenir Light' !important;
}
.btn-xl {
  padding: 15px 30px;
}
::-moz-selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
::selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
img::selection {
  color: white;
  background: transparent;
}
img::-moz-selection {
  color: white;
  background: transparent;
}

/*.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}*/
@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}
@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
        padding-bottom: 0rem;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important;
    }
}
@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }
}
